import { fetchBaseQuery } from "@reduxjs/toolkit/query";
import env from "#env";
import createApi from "../../../createApi";

import { QUERY_TAGS } from "#constants/query";
import { AdminOrganisation, SearchOrganisationsRequest } from "#customTypes/admin";
import { buildInfiniteQuery } from "#utils/useInfiniteQuery";

export const superAdminApi = createApi({
  reducerPath: "superAdmin",
  baseQuery: fetchBaseQuery({
    baseUrl: `${env.VITE_API_BASE_URL}/superadmin/`,
  }),
  tagTypes: [QUERY_TAGS.SearchOrganisations],
  endpoints: (builder) => ({
    searchOrganisations: buildInfiniteQuery<
      AdminOrganisation,
      SearchOrganisationsRequest
    >({
      builder,
      query: (params) => ({
        url: `search-organisations`,
        method: "GET",
        params,
        credentials: "include",
      }),
      providesTags: () => [{ type: QUERY_TAGS.SearchOrganisations, id: "all" }],
      serializeArgs: ["search", "type"],
    }),
  }),
});

export const { useSearchOrganisationsQuery } = superAdminApi;
