export enum QUERY_TAGS {
  OrganisationWla = "organisation-wla",
  Organisation = "organisation",
  MyPositions = "my-positions",
  TeamMembers = "team-members",
  Channel = "channel",
  MyOwnedChannels = "my-owned-channels",
  MyFollowedChannels = "my-followed-channels",
  SuggestedChannels = "suggested-channels",
  Interests = "interests",
  MyInterests = "my-interests",
  ScreenerBriefs = "screener-briefs",
  Briefs = "briefs",
  Brief = "brief",
  ChannelMembers = "channel-members",
  ChannelToken = "channel-token",
  BriefCardInfo = "brief-card-info",
  ChannelAdminBriefs = "channel-admin-briefs",
  OauthStatus = "oauth-status",
  OauthImport = "oauth-import",
  OauthChannelImports = "oauth-channel-imports",
  ChannelImports = "channel-imports",
  SearchOrganisations = "superadmin-organisations",
  BriefBuilder = "brief-builder",
  BriefBuilderSteps = "brief-builder-steps",
  BriefBuilderLock = "builder-lock",
  Wallpapers = "wallpapers",
  UnsplashPhoto = "unsplash-photo",
  UserCustomProperties = "user-custom-properties",
  UserCustomPropertyValues = "user-custom-property-values",
}
