import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { TOAST_DEFAULT_TIMEOUT } from "#constants/common";
import { ToastOptions } from "#shared/types/toast";

type ToastState = ToastOptions[];

const initialState: ToastState = [];

export const showToast = createAsyncThunk(
  "toast/showToast",
  async ({ id, duration, ...options }: ToastOptions, { dispatch }) => {
    dispatch(addToast({ id, duration, ...options }));
    setTimeout(() => {
      dispatch(removeToast(id));
    }, duration || TOAST_DEFAULT_TIMEOUT);
  }
);

const toastSlice = createSlice({
  name: "toast",
  initialState,
  reducers: {
    addToast: (state, action: PayloadAction<ToastOptions>) => {
      state.push(action.payload);
    },
    removeToast: (state, action: PayloadAction<number>) => {
      return state.filter((toast) => toast.id !== action.payload);
    },
    removeAllToasts: () => {
      return [];
    },
  },
});

export const selectToast = (state: RootState) => state.toast;

export const { addToast, removeToast, removeAllToasts } = toastSlice.actions;

export default toastSlice.reducer;
