import { ExportBriefType } from "#constants/export";
import { PaginatedResponse, PaginationRequest } from "#customTypes/pagination";

export interface ScreenerBrief {
  id: number;
  briefRef: string;
  name: string;
}

export interface GetScreenerBriefsResponse {
  data: ScreenerBrief[];
}

export enum BriefRewardUnit {
  Pounds = "pounds",
  Voucher = "unit",
}

export interface BriefReward {
  rewardId: number;
  organisationId: number;
  name: string;
  curUnits: number;
  maxUnits: number;
  unit: BriefRewardUnit;
}

export interface BriefLinkedBrief {
  briefId: number;
  briefRef: string;
  title: string;
  description: string;
  hasRewards: boolean;
  bannerImage: string;
  primaryColor?: string;
  language?: { id: string; code: string } | null;
  project?: { id: string; name: string } | null;
}

export interface BriefCardInfo {
  briefId: number;
  briefRef: string;
  channelRef: string;
  parentBriefId: number | null;
  rewards: BriefReward[];
  linkedBriefs: BriefLinkedBrief[];
}

export interface GetBriefExportUrlRequest {
  briefRef: string;
  type: ExportBriefType;
}

export interface GetBriefExportUrlResponse {
  url: string;
}

export enum ReportBriefError {
  RateLimit = "you have reached the maximum number of reports",
}

export interface ReportBriefRequest {
  briefRef: string;
  description: string;
}

export interface DeleteBriefResponse {
  deletedBriefs: {
    briefId: number;
    briefRef: string;
  }[];
}

export interface DeleteBriefsRequest {
  briefRefs: string[];
}

export enum AdminBriefStatus {
  Active = "active",
  Deactivated = "deactivated",
  Expired = "expired",
  Scheduled = "scheduled",
  Draft = "draft",
}

export interface AdminBrief {
  id: number;
  briefRef: string;
  title: string;
  description: string;
  isActivated: boolean;
  isRemoved: boolean;
  bannerImage: string;
  endsOn: string;
  hasLinkedBriefs: boolean;
  parentBriefId: number | null;
  channelId: number;
  hasRewards: boolean;
  status: AdminBriefStatus;
}

export interface GetChannelAdminBriefsRequest extends PaginationRequest {
  channelRef: string;
  search?: string;
}

export type GetChannelAdminBriefsResponse = PaginatedResponse<AdminBrief>;
