import env from "#env";
import createApi from "../../../createApi";

import { QUERY_TAGS } from "#constants/query";
import { appBaseQuery } from "#features/common/baseQuery";
import { BriefWallpaperResponse } from "#customTypes/briefBuilder";

export const wallpapersApi = createApi({
  reducerPath: "wallpapers",
  baseQuery: appBaseQuery({
    baseUrl: `${env.VITE_API_BASE_URL}/community/wallpapers`,
  }),
  tagTypes: [QUERY_TAGS.Wallpapers],
  endpoints: (builder) => ({
    fetchWallpapers: builder.query<BriefWallpaperResponse, void>({
      query: () => ({
        url: "",
        method: "GET",
        credentials: "include",
      }),
    }),
  }),
});

export const { useFetchWallpapersQuery } = wallpapersApi;
