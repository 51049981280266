import { QUERY_TAGS } from "#constants/query";
import { PaginatedResponse, PaginationRequest } from "#customTypes/pagination";
import { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import { QueryHooks } from "@reduxjs/toolkit/dist/query/react/buildHooks";
import { ApiEndpointQuery, BaseQueryFn, QueryDefinition } from "@reduxjs/toolkit/query";
import { DotNestedKeys } from "#shared/utils";
import { AxiosRequestConfig } from "axios";
import { omit, pick } from "radash";
import { useCallback, useState } from "react";

type InfiniteQueryOptions = {
  pageSize?: number;
  skip?: boolean;
};

interface PaginationQueryState<TData> {
  data: TData[];
  totalItems: number;
  hasNextPage: boolean;
  isLoading: boolean;
  isFetching: boolean;
  isFetchingNextPage: boolean;
  loadNextPage: () => void;
  refetch: () => void;
}

type QueryDefinitionWithMerge<TData> = QueryDefinition<
  any,
  any,
  any,
  PaginatedResponse<TData>
>;

type PagedQueryEndpoint<TData> = ApiEndpointQuery<QueryDefinitionWithMerge<TData>, any> &
  QueryHooks<QueryDefinitionWithMerge<TData>>;

interface PaginatedQueryConfig<DataType, QueryArgType extends PaginationRequest> {
  builder: EndpointBuilder<BaseQueryFn, string, string>;
  query: (params: Omit<QueryArgType, "page" | "pageSize">) => AxiosRequestConfig;
  providesTags?: (
    result: PaginatedResponse<DataType> | undefined,
    error: unknown,
    queryArgs: QueryArgType
  ) => { type: QUERY_TAGS; id: string }[];
  serializeArgs?: DotNestedKeys<Omit<QueryArgType, "page" | "pageSize">>[];
}

/**
 * Build a query for fetching infinite data.
 * By sharing the cache key between pages, and appending the new data to the old rather than overwriting it,
 * the single cache entry will contain all the fetched pages of data.
 *
 * @param builder - The endpoint builder to use
 * @param query - A function that takes the query args and returns an AxiosRequestConfig for the request (url, params etc)
 * @param providesTags - A function that takes the response and returns an array of tags to invalidate
 * @param serializeArgs - A list of keys which is used for updating the cache
 *
 * @example
 * endpoints: builder => ({
 *  fetchChannelMembers: buildInfiniteQuery<ChannelMember, GetChannelMembersRequest>({
 *    builder,
 *    query: ({ isPublicUser, ...params }) => ({
 *       url: isPublicUser ? `members-public` : `members`,
 *       method: "GET",
 *       params,
 *       credentials: "include",
 *    }),
 *    providesTags: (_result, _error, { channelRef }) => [
 *      { type: QUERY_TAGS.ChannelMembers, id: channelRef },
 *    ],
 *    serializeArgs: ["channelRef"],
 * }),
 *
 * @returns The query definition
 */
export const buildInfiniteQuery = <DataType, QueryArgType extends PaginationRequest>({
  builder,
  query,
  providesTags,
  serializeArgs = [],
}: PaginatedQueryConfig<DataType, QueryArgType>) =>
  builder.query<PaginatedResponse<DataType>, QueryArgType>({
    query: ({ page = 1, pageSize, ...params }) => {
      const requestConfig = query(params);

      delete requestConfig.params.skip;

      return {
        ...requestConfig,
        params: { page, pageSize, ...requestConfig.params },
      };
    },
    serializeQueryArgs: ({ endpointName, queryArgs }) => {
      return JSON.stringify({
        endpointName,
        ...pick(queryArgs, serializeArgs),
      });
    },
    merge: (previous, response) => {
      if (previous && response.pagination.page > (previous.pagination.page || 1)) {
        return {
          data: [...(previous.data || []), ...(response.data || [])],
          pagination: response.pagination,
        };
      }

      return response;
    },
    forceRefetch: ({ previousArg, currentArg }) => {
      if (serializeArgs.length) {
        return (
          JSON.stringify(omit(previousArg, serializeArgs)) !==
          JSON.stringify(omit(currentArg, serializeArgs))
        );
      }

      return JSON.stringify(previousArg) !== JSON.stringify(currentArg);
    },
    providesTags,
  });

/**
 * A function that can be used to build a query hook that will take care of fetching and merging results from a paginated endpoint
 *
 * @example
 * const infiniteQuery = useInfiniteQuery(usersApi.endpoints.getUsers)
 *
 * const { data, isFetchingNextPage, fetchNextPage, hasNextPage } = infiniteQuery()
 *
 * <Button disabled={isFetchingNextPage || hasNextPage} onClick={fetchNextPage}>Load more</Button>
 */
export const useInfiniteQuery =
  <DataType>(pagedEndpoint: PagedQueryEndpoint<DataType>) =>
  (
    params = {},
    options: InfiniteQueryOptions = { pageSize: 10 }
  ): PaginationQueryState<DataType> => {
    const [isFetchingNextPage, setIsFetchingNextPage] = useState(false);

    const query = pagedEndpoint.useQuery(
      { ...params, ...options },
      { skip: options.skip }
    );

    const { data, isLoading, isFetching, error } = query;
    const { pagination = { page: 1, totalPages: 1, total: 0 } } = data ?? {};
    const { page, totalPages, total } = pagination;

    const hasNextPage = page < totalPages;

    const [triggerQuery] = pagedEndpoint.useLazyQuery();

    const isRefetching = isLoading || isFetching;

    const refetch = useCallback(async () => {
      if (!error && !isRefetching) {
        for (let i = 1; i <= page; i++) {
          await triggerQuery({ page: i, pageSize: options.pageSize, ...params });
        }
      }
    }, [page, triggerQuery, params, options, error, isRefetching]);

    const loadNextPage = useCallback(() => {
      if (!error && !options.skip && hasNextPage && !isRefetching) {
        setIsFetchingNextPage(true);
        triggerQuery({ page: page + 1, pageSize: options.pageSize, ...params })
          .unwrap()
          .finally(() => setIsFetchingNextPage(false));
      }
    }, [hasNextPage, isRefetching, page, triggerQuery, params, options, error]);

    return {
      ...query,
      data: query.data?.data || [],
      isLoading: query.isLoading,
      isFetching: isRefetching,
      totalItems: total,
      isFetchingNextPage,
      loadNextPage,
      refetch,
      hasNextPage,
    };
  };
