import env from "#env";
import createApi from "../../../createApi";

import { QUERY_TAGS } from "#constants/query";
import { appBaseQuery } from "#features/common/baseQuery";
import { SearchUnsplashPhotosRequest, UnsplashPhoto } from "#customTypes/unsplash";
import { buildInfiniteQuery } from "#utils/useInfiniteQuery";

export const unsplashApi = createApi({
  reducerPath: "unsplash",
  baseQuery: appBaseQuery({
    baseUrl: `${env.VITE_API_BASE_URL}/community/unsplash/`,
  }),
  tagTypes: [QUERY_TAGS.UnsplashPhoto],
  endpoints: (builder) => ({
    fetchUnsplashPhoto: builder.query<UnsplashPhoto, string>({
      query: (id) => ({
        url: `${id}`,
        method: "GET",
        params: { id },
        credentials: "include",
      }),
      providesTags: (_res, _, id) => [{ type: QUERY_TAGS.UnsplashPhoto, id }],
    }),
    // Same endpoint as fetchUnsplashPhoto
    downloadUnsplashPhoto: builder.mutation<UnsplashPhoto, string>({
      query: (id) => ({
        url: `${id}`,
        method: "GET",
        params: { id },
        credentials: "include",
      }),
    }),
    searchUnsplashPhotos: buildInfiniteQuery<UnsplashPhoto, SearchUnsplashPhotosRequest>({
      builder,
      query: (params) => ({
        url: "search",
        method: "GET",
        params,
        credentials: "include",
      }),
      serializeArgs: ["query", "orientation"],
    }),
  }),
});

export const {
  useFetchUnsplashPhotoQuery,
  useSearchUnsplashPhotosQuery,
  useDownloadUnsplashPhotoMutation,
} = unsplashApi;
