import { FetchBriefAndCommentsByRefProps } from "#customTypes/types";
import { Brief } from "#customTypes/db";
import { get } from "#shared/components/api/http";
import env from "#env";
import { getHeaders } from "#shared/components/api/utils";

export default async function fetchBriefByRef({
  cookies,
  briefRef,
}: FetchBriefAndCommentsByRefProps): Promise<Brief | null> {
  try {
    return await get<Brief>(`${env.VITE_API_BASE_URL}/community/briefs/${briefRef}`, {
      headers: getHeaders(cookies),
      credentials: "include",
    });
  } catch (e) {
    console.log("Error when fetching brief");
    return null;
  }
}
