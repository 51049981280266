import { fetchBaseQuery } from "@reduxjs/toolkit/query";
import env from "#env";
import createApi from "../../../createApi";

export const tiptapApi = createApi({
  reducerPath: "tiptap",
  baseQuery: fetchBaseQuery({
    baseUrl: `${env.VITE_API_BASE_URL}/tiptap/`,
  }),
  tagTypes: [],
  endpoints: (builder) => ({
    fetchTiptapToken: builder.query<{ token: string }, void>({
      query: () => ({
        url: `token`,
        method: "POST",
        credentials: "include",
      }),
    }),
  }),
});

export const { useFetchTiptapTokenQuery } = tiptapApi;
