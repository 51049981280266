import { GOD_VIEW_HEADER, WLA_HEADER } from "#constants/common";
import env from "#env";
import axios from "axios";

export const client = axios.create({
  baseURL: env.VITE_API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
  timeout: 10000, // 10 seconds
  withCredentials: true,
});

type Config = {
  wlaId?: number;
  godViewId?: number;
};

export const configureAxiosHeaders = ({ wlaId, godViewId }: Config) => {
  client.defaults.headers[WLA_HEADER] = wlaId?.toString() || null;
  client.defaults.headers[GOD_VIEW_HEADER] = godViewId?.toString() || null;
};
