import { FetchBaseQueryError, fetchBaseQuery } from "@reduxjs/toolkit/query";
import env from "#env";
import createApi from "../../../createApi";

import { QUERY_TAGS } from "#constants/query";
import {
  ChannelImportItem,
  ChannelImportStatus,
  GetChannelImportsResponse,
} from "#customTypes/import";
import {
  getChannelImports,
  getChannelPolledImportStatus,
} from "#apis/import/getChannelImports";

export const channelImportsApi = createApi({
  reducerPath: "channelImports",
  baseQuery: fetchBaseQuery({
    baseUrl: `${env.VITE_API_BASE_URL}/community/channel-imports/`,
  }),
  tagTypes: [QUERY_TAGS.ChannelImports],
  endpoints: (builder) => ({
    fetchImportsForChannel: builder.query<GetChannelImportsResponse, number>({
      queryFn: async (channelId: number) => {
        try {
          const response = await getChannelImports(channelId);

          if (!response) {
            throw new Error("No response");
          }

          return { data: response };
        } catch (error) {
          console.error(error);
          return { error: error as FetchBaseQueryError };
        }
      },
      providesTags: (_result, _error, channelId) => [
        { type: QUERY_TAGS.ChannelImports, id: channelId },
        { type: QUERY_TAGS.ChannelImports, id: "all" },
      ],
    }),
    fetchChannelImportStatus: builder.query<ChannelImportStatus, ChannelImportItem>({
      queryFn: async (item) => {
        try {
          const response = await getChannelPolledImportStatus(item);

          if (!response) {
            throw new Error("No response");
          }

          return { data: response };
        } catch (error) {
          console.error(error);
          return { error: error as FetchBaseQueryError };
        }
      },
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        const { data: status } = await queryFulfilled;

        if (
          [ChannelImportStatus.OauthPending, ChannelImportStatus.FilePending].includes(
            status
          )
        ) {
          return;
        }

        dispatch(
          channelImportsApi.util.invalidateTags([
            { type: QUERY_TAGS.ChannelImports, id: args.channelId },
          ])
        );
      },
    }),
  }),
});

export const { useFetchImportsForChannelQuery, useFetchChannelImportStatusQuery } =
  channelImportsApi;
