import { fetchBaseQuery } from "@reduxjs/toolkit/query";
import env from "#env";
import createApi from "../../../createApi";

import { QUERY_TAGS } from "#constants/query";
import { Cookies } from "#shared/types";
import { getHeaders } from "#shared/components/api/utils";
import { GetInterestsResponse } from "src/types/interests";

export const interestsApi = createApi({
  reducerPath: "interests",
  baseQuery: fetchBaseQuery({
    baseUrl: `${env.VITE_API_BASE_URL}/community/interests/`,
  }),
  tagTypes: [QUERY_TAGS.Interests],
  endpoints: (builder) => ({
    fetchInterests: builder.query<GetInterestsResponse, { cookie?: Cookies } | undefined>(
      {
        queryFn: async (args, _queryApi, _extraOptions, fetchWithBQ) => {
          const response = await fetchWithBQ({
            url: "",
            method: "GET",
            headers: getHeaders(args?.cookie),
            credentials: "include",
          });

          return response.data
            ? { data: response.data as GetInterestsResponse }
            : { data: [] };
        },
        serializeQueryArgs: () => QUERY_TAGS.Interests,
        providesTags: [QUERY_TAGS.Interests],
      }
    ),
  }),
});

export const { useFetchInterestsQuery } = interestsApi;
