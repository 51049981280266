import { AxiosError } from "axios";

import { QueryParams, objectToGetParams } from "#shared/utils/url.utils";

export function getErrorMessage(error: unknown): string | undefined {
  if (typeof error === "object" && error != null && "message" in error) {
    return (error as any).message;
  }
  return undefined;
}

export function getAxiosErrorMessage(error: unknown): string | undefined {
  const axiosError = error as AxiosError;

  if (axiosError.isAxiosError) {
    return (
      getErrorMessage(axiosError.response?.data)?.toLowerCase() || axiosError.message
    );
  }

  if ("data" in axiosError) {
    return getErrorMessage(axiosError.data)?.toLowerCase() || axiosError.message;
  }

  return getErrorMessage(error);
}

export function appendParamsToUrl(url: string, params: QueryParams) {
  return `${url}${url.includes("?") ? "&" : "?"}${objectToGetParams(params).replace(
    /^\?/,
    ""
  )}`;
}
