import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import env from "#env";
import createApi from "../../../createApi";
import {
  CreateOrganisationRequest,
  CreateOrganisationResponse,
  DeleteOrganisationRequest,
  RequestWLARequest,
  UpdateOrganisationRequest,
} from "../../types/types";
import uploadPresignedFile from "#apis/upload/uploadPresignedFile";
import { Organisation } from "src/types/db";
import { organisationPositionsApi } from "#features/organisationPositions/organisationPositionsAPI";
import { QUERY_TAGS } from "#constants/query";
import generatePresignedUrl, {
  PresignedUrlType,
} from "#apis/upload/generatePresignedUrl";
import { channelsApi } from "#features/channel/channelsAPI";
import { appBaseQuery } from "#features/common/baseQuery";
import { removeGodView, updateGodView } from "#features/superAdmin/godViewSlice";
import { superAdminApi } from "#features/superAdmin/superAdminAPI";

export const organisationsApi = createApi({
  reducerPath: "organisations",
  baseQuery: appBaseQuery({
    baseUrl: `${env.VITE_API_BASE_URL}/community/organisations/`,
  }),
  tagTypes: [QUERY_TAGS.Organisation, QUERY_TAGS.MyOwnedChannels, QUERY_TAGS.MyPositions],
  endpoints: (builder) => ({
    fetchOrganisationById: builder.query<Organisation, number>({
      query: (organisationId) => ({
        url: `${organisationId}`,
        credentials: "include",
      }),
      providesTags: (_result, _error, organisationId) => [
        { type: QUERY_TAGS.Organisation, id: organisationId },
      ],
    }),
    createOrganisation: builder.mutation<
      CreateOrganisationResponse,
      CreateOrganisationRequest
    >({
      async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
        const { name, logo, website, teamMembers, migratePersonalChannel } = args;

        const response = await fetchWithBQ({
          url: "create",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name,
            website,
            teamMembers,
            migratePersonalChannel,
          }),
          credentials: "include",
        });

        // get organisation id from response
        const organisationId = (response.data as CreateOrganisationResponse)
          ?.organisationId;

        if (!organisationId) {
          throw new Error("No organisation id received");
        }

        // generate presigned url for logo upload
        const presignedResponse = await generatePresignedUrl({
          type: PresignedUrlType.OrganisationLogo,
          body: { organisationId },
        });

        if (!presignedResponse) {
          throw new Error("No presigned URL received");
        }

        try {
          // upload logo to S3
          await uploadPresignedFile({ data: presignedResponse, file: logo });
        } catch (error) {
          console.error(error);
        }

        return response.data
          ? { data: response.data as CreateOrganisationResponse }
          : { error: response.error as FetchBaseQueryError };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(organisationPositionsApi.util.invalidateTags([QUERY_TAGS.MyPositions]));
        dispatch(channelsApi.util.invalidateTags([QUERY_TAGS.MyOwnedChannels]));
      },
    }),
    updateOrganisation: builder.mutation<
      CreateOrganisationResponse,
      UpdateOrganisationRequest
    >({
      async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
        const { organisationId, name, logo, primaryColor, website } = args;

        let presignedResponse = null;

        if (logo) {
          presignedResponse = await generatePresignedUrl({
            type: PresignedUrlType.OrganisationLogo,
            body: { organisationId },
          });

          if (!presignedResponse) {
            throw new Error("No presigned URL received");
          }

          await uploadPresignedFile({ data: presignedResponse, file: logo });
        }

        const response = await fetchWithBQ({
          url: organisationId.toString(),
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name,
            website,
            primaryColor,
            saveLogo: !!presignedResponse?.needsSaving,
          }),
          credentials: "include",
        });

        return response.data
          ? { data: response.data as CreateOrganisationResponse }
          : { error: response.error as FetchBaseQueryError };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        const response = await queryFulfilled;
        dispatch(organisationPositionsApi.util.invalidateTags([QUERY_TAGS.MyPositions]));

        if (response.data) {
          dispatch(
            updateGodView({
              name: response.data.name,
              logo: response.data.logo,
            })
          );
        }
      },
      invalidatesTags: (_result, _error, arg) => [
        { type: QUERY_TAGS.Organisation, id: arg.organisationId },
      ],
    }),
    requestWla: builder.mutation<void, RequestWLARequest>({
      query: (body) => ({
        url: "request-white-label",
        method: "POST",
        body,
        credentials: "include",
      }),
      invalidatesTags: (_result, _error, arg) => [
        { type: QUERY_TAGS.Organisation, id: arg.organisationId },
      ],
    }),
    deleteOrganisation: builder.mutation<void, DeleteOrganisationRequest>({
      query: ({ organisationId }) => ({
        url: `/delete-business-account`,
        method: "POST",
        body: { organisationId },
        credentials: "include",
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(channelsApi.util.invalidateTags([QUERY_TAGS.MyOwnedChannels]));
        dispatch(superAdminApi.util.resetApiState());
        dispatch(removeGodView(args.organisationId));
      },
    }),
  }),
});

export const {
  useCreateOrganisationMutation,
  useUpdateOrganisationMutation,
  useDeleteOrganisationMutation,
  useRequestWlaMutation,
  useFetchOrganisationByIdQuery,
} = organisationsApi;
