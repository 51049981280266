import { fetchBaseQuery } from "@reduxjs/toolkit/query";
import env from "#env";
import createApi from "../../../createApi";
import { QUERY_TAGS } from "#constants/query";
import { Organisation } from "#customTypes/db";

export const wlaOrganisationApi = createApi({
  reducerPath: "wlaOrganisation",
  baseQuery: fetchBaseQuery({
    baseUrl: `${env.VITE_API_BASE_URL}/community/organisations/domain`,
  }),
  tagTypes: [QUERY_TAGS.OrganisationWla],
  endpoints: (builder) => ({
    fetchWlaOrganisation: builder.query<Organisation, string | undefined>({
      query: (domain: string) => {
        return {
          url: domain,
        };
      },
      transformResponse: (organisation: Organisation) => organisation,
      serializeQueryArgs: () => QUERY_TAGS.OrganisationWla,
    }),
  }),
});

export const { useFetchWlaOrganisationQuery } = wlaOrganisationApi;
export type UseFetchWLAOrganisation = typeof useFetchWlaOrganisationQuery;
