import { fetchBaseQuery } from "@reduxjs/toolkit/query";
import env from "#env";
import createApi from "../../../createApi";
import { QUERY_TAGS } from "#constants/query";
import { InterestsList, UpdateMyInterestsRequest } from "#customTypes/interests";

export const userInterestsApi = createApi({
  reducerPath: "userInterests",
  baseQuery: fetchBaseQuery({
    baseUrl: `${env.VITE_API_BASE_URL}/community/users-interests/`,
  }),
  tagTypes: [QUERY_TAGS.MyInterests, QUERY_TAGS.SuggestedChannels],
  endpoints: (builder) => ({
    fetchMyInterests: builder.query<InterestsList, void>({
      query: () => ({
        url: "",
        credentials: "include",
      }),
      providesTags: [QUERY_TAGS.MyInterests],
    }),
    updateMyInterests: builder.mutation<InterestsList, UpdateMyInterestsRequest>({
      query: (body) => ({
        url: "",
        method: "PUT",
        body,
        credentials: "include",
      }),
      invalidatesTags: [QUERY_TAGS.MyInterests, QUERY_TAGS.SuggestedChannels],
    }),
  }),
});

export const { useFetchMyInterestsQuery, useUpdateMyInterestsMutation } =
  userInterestsApi;
